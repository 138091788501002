import React from "react";
import LokalitaTemplate from "../../templates/LokalitaTemplate";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplate from "../../templates/RegionyTemplate";
import UniversalTemplate from "../../templates/UniversalTemplate";
import CenovaKalkulacka from "../../components/CenovaKalkulacka";



const faqData = [
  {
    otazka: "Budu platit něco navíc?",
    odpoved: "Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom nemocnice nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo Jihlavu. To se pak počítá podle najetých kilometrů."
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.<br/><br/>Pokud jsme pro vašeho blízkého jeli nebo pojedeme k vám domů, bude k celkové částce připočítán poplatek 1.990 Kč (v Praze a Ostravě 3.490 Kč), který pokrývá pohotovostní služby zaměstnanců zajišťujících akutní vyzvednutí.`
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];


export const query = graphql`
  query {
    zizkova: file(relativePath: { eq: "jihlava-zizkova.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const JihlavaZizkova = ({data}) => {


  return(
    <UniversalTemplate 
      h1="Pohřební služba Jihlava Žižkova"
      title={"Pohřební služba Jihlava Žižkova: Empatická & Dostupné ceny"}
      desc="Jsme spolehlivá jihlavská pohřební služba v blízkosti ulice Žižkova a Nemocnice Jihlava, s působností po celé Jihlavě a okolí."
      perex={<>Pokud váš blízký zemřel v Nemocnici Jihlava, Domově Pro Seniory - Lesnov, nebo u vás doma v blízkosti ulice Žižkova, v Hruškových Dvorech, Horním Kosově nebo kdekoliv jinde v Jihlavě, můžete se obrátit na naši pohřební službu. Jsme vám k dispozici na telefonu 24 hodin 7 dní v týdnu.</>}
      imgFixed={data.zizkova.childImageSharp.fixed}
      imgAlt="Nemocnice Jihlava"
      breadscrumStranky={[
        {name: "Domů", url: "/"},
        {name: "Pohřební služba Jihlava", url: "/pohrebni-sluzba-jihlava/"},
        {name: "Pohřební služba Jihlava Žižkova", url: "/pohrebni-sluzba-jihlava/zizkova/"}
      ]}
      faqData={faqData}
      mesto="Jihlava"
      seVsimText={"Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení v Jihlavě, Žižkova a okolí."}
      
      content={<>
        <p>Jsme spolehlivá <Link to="/pohrebni-sluzba-jihlava/">pohřební služba v Jihlavě</Link>, která se postará o důstojné poslední rozloučení s vaším blízkým. Pomůžeme vám vše vyřídit přes internet a z bezpečí vašeho domova. Můžete se na nás obrátit ať máte zájem o kremaci bez obřadu nebo rozloučení v jedné ze smutečních síní. Obřad je možné zajistit například v <Link to="/obradni-sine/kaple-jihlava/">Kapli na Ústředním hřbitově</Link> nebo <Link to="/obradni-sine/obradni-sin-krematoria-jihlava/">Obřadní síni krematoria Jihlava</Link> na ulici Smrčenská na severu Jihlavy v části Lesnov.</p>
        <p>Pokud váš blízký zemřel v Nemocnici Jihlava, kontaktujte nás a my už se s nemocnicí domluvíme na jeho vyzvednutí. V případě <Link to="/poradna/umrti-blizke-osoby-pruvodce/">úmrtí doma</Link> se můžete obrátit na naši nonstop linku a do několika málo hodin k vám přijedeme, ať bydlíte v centru Jihlavy v blízkosti ulice Žižkova, v městské části Kosov nebo Staré Hory.</p>

        <CenovaKalkulacka compact />

        <h2>Jaký je postup?</h2>
        
        <h3>Nezávazně si zavoláme a probereme vaši situaci</h3>
        <p>Chápeme, že smrt blízkého je událost, kterou neplánujete. Na naší telefonní lince jsme vždy připraveni vaši situaci řešit, ať je den či noc, všední den či víkend. Probereme společně vaši představu a najdeme ideální řešení pro vás a vaši rodinu, ať už se rozhodnete pro kremaci bez obřadu nebo obřad v některé z Jihlavských obřadních síní. Na telefonu jsme pro vás každý den 24/7.</p>
        
        <h3>Pohřeb s námi naplánujete z bezpečí domova</h3>
        <p>Na plánování budete mít dostatek času a nemusíte nikam chodit. Vše s vámi vyřešíme přes po telefonu a přes e-mail. Bez zbytečného stresu a tlaku si díky tomu můžete rozmyslet detaily obřadu od <Link to="/poradna/smutecni-kytice-pohreb/">květinové výzdoby</Link> přes hudební doprovod až po ozdobný obal na urnu nebo <Link to="/slovnik-pojmu/pamatecni-predmety/">památeční předmět</Link> vytvořený z popela. My jsme vám celou dobu k dispozici a připraveni pomoci.</p>
        
        <h3>O vše ostatní se už postaráme my</h3>
        <p>Ať už se rozhodnete pro jakýkoli druh pohřbu, naší prioritou je, abyste se s vaším blízkým mohli rozloučit důstojně a podle vašich představ. My jsme tu, abychom vyřešili komunikaci s matrikou, připravení smuteční síně, upravení a oblečení zesnulého a další náležitosti.</p>

        <h2>V čem jsme jiní než ostatní pohřební služby v Jihlavě?</h2>

        <h3>Vše vyřešíme přes internet</h3>
        <p>Ať váš blízký zemřel v Nemocnici, v Domově Pro Seniory Lesnov nebo u vás doma v blízkosti ulice Žižkova či kdekoliv jinde v Jihlavě, nemusíte nikam chodit. Vše vyřídíte z bezpečí domova, kde si můžete všechny detaily posledního rozloučení v klidu promyslet s rodinou.</p>
        
        <h3>Vždy za transparentní ceny</h3>
        <p>Cena, na které se domluvíme, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, včetně paušálních kilometrů po celé Jihlavě, nejen v okolí Žižkovy ulice.</p>
        
        <h3>Poskytneme vám speciální péči</h3>
        <p>Chápeme, že úmrtí blízké osoby je nečekaná událost. Proto jsme připraveni ji s vámi řešit v čase, který vyhovuje vám a vaší rodině. Ať už to bude v 10 hodin večer, až půjdou děti spát, nebo během sobotního odpoledne, kdy nebudete rušeni pracovními hovory. Ozvat se nám můžete 24 hodin 7 dní v týdnu.</p>

      </>}
    />
  );
}


export default JihlavaZizkova;
